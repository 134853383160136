import React, { Component } from 'react'
import swal from 'sweetalert';
import nbnIcon from './../../../src/assets/img/nbn-icon.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import { getApiHeader } from '../../utils/CommonFunctions';
import axios from 'axios';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   


class MyServices extends Component {
    state = {
        customer_cards: [],
        loading: true,
        clientToken: null,
        customerId: null,
        nbn_plan_details: [],
        activation_details: [],
        activated_plan_data: [],
        nbn_activation_expiry_date: "",
    }

    componentDidMount() {
        this.getMyservices();
    }
    getMyservices = async () => {

        this.setState({ loading: true });
        let headers = await getApiHeader();
        const post_data = {
            nbn_activation_id: sessionStorage.getItem('nbn_activation_id')
        };
         await axios.post(`${SERVER_URL}my-services-list`, post_data, { headers }).then(response => {
    
            console.log(response.data.data.data);
            if (response.data.data.data.customMessage) {
                if (response.data.data.customMessage !== 'undefined') {
                    this.setState({ customMesssge: response.data.data.customMessage });
                }
            }
            let data = response.data.data.data;
            console.log('response.data------------->',response.data.data.data.activation);
            if(response.data.data.data.nbn_activation.plan){
              this.setState({ nbn_plan_details:response.data.data.data.nbn_activation.plan})
            }
            if(response.data.data.data.activation){
             
              this.setState({ activation_details:response.data.data.data.activation, 
                              activated_plan_data:response.data.data.data.activation.activated_plan_data
                    })
            }
            if (data.telsim_customer_id) {
              this.setState({ customerId: data.telsim_customer_id })
            }
            
            this.setState({ loading: false });
            console.log(this.state.nbn_plan_details);
    
        }).catch(error => {
            
            this.setState({ loading: false });
            if ((typeof (error.response.data.data.data.message) !== 'undefined') && (typeof (error.response.data.data) !== 'undefined')) {
    
                swal({
                    text: error.response.data.message,
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    icon: "warning"
                }).then(() => {
    
                });
            }
            else {
                swal("Internal Server Error", "", "warning");
            }
        });
      }
    render() {
        return (
           <>
           <div className="container">
          <section className="nbn-my-account">
            <div className="nbn-my-service">
              <div className="row">
                <div className="col-md-7">
                  <h3>MY SERVICES</h3>
                </div>
                <div className="col-md-5">
                  <p>Customer ID : <span>CUS-{this.state.customerId}</span></p>
                </div>
              </div>
            </div>

            {sessionStorage.getItem('is_nbn') === '1' && <div className="nbn-data-section">
              <div className="row">
                <div className="col-md-1 py-4">
                  <center><img src={nbnIcon} alt="NBN icon" /></center>
                </div>
                <div className="col-md-4 py-4">
                  <div className="nbn-data-content">
                    <p>{this.state.service_address}</p>
                    <strong>{this.state.nbn_plan_details ? this.state.nbn_plan_details.plan_display_name : ''}</strong>
                    <p className="nbn-data-unlimited">{this.state.nbn_plan_details.plan_name ? this.state.nbn_plan_details.plan_name : ""} | UNLIMITED</p>
                  </div>
                </div>
                <div className="col-md-5 py-4">
                  <div className="nbn-data-table">
                    <table className="table table-borderless tab-list">
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'right' }}>Billing Cycle Ends</td>
                          <td>
                            <div className="nbn-progress">
                              <div className="nbn-progress-bar10"></div>
                            </div>
                          </td>
                          <td style={{ textAlign: 'left', fontWeight: 600 }}></td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: 'right' }}>Data</td>
                          <td>
                            <div className="nbn-progress">
                              <div className="nbn-skills nbn-progress-bar15"></div>
                            </div>
                          </td>
                          <td style={{ textAlign: 'left', fontWeight: 600 }}> { this.state.nbn_plan_details.plan_name ? this.state.nbn_plan_details.plan_name : ''} UNLIMITED</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-2 py-4">
                  <center><a href="/nbn-my-account-summary"><button type="button" className="btn btn-danger btn-data">Manage <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></button></a></center>
                </div>
              </div>
            </div>}

                <br />
                
                {sessionStorage.getItem('is_prepaid') === '1' &&
                  this.state.activation_details.map((activationDetail, index) => (
                    <div className="nbn-data-section2" key={index}>
                      <div className="row">
                        <div className="col-md-1 py-4">
                          <center><img src={nbnIcon} alt="mobile icon" /></center>
                        </div>
                        <div className="col-md-4 py-4">
                          <div className="nbn-data-content">
                            <p>{activationDetail.mobile_number}</p>
                            <strong>Telsim Prepaid Plan</strong>
                            <p className="nbn-data-unlimited">{activationDetail.activated_plan_data.my_service_plan_display_name ?? ''}</p>
                          </div>
                        </div>
                        <div className="col-md-5 py-4">
                          <div className="data-table">
                            <table className="table table-borderless tab-list">
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'right' }}>Allowances Reset</td>
                                  <td>
                                    <div className="progress">
                                      <div className="nbn-skills" style={{ '--progress-width': '40%' }} />
                                    </div>
                                  </td>
                                  <td style={{ fontWeight: 600, textAlign: 'left' }}>{activationDetail.expiry_date}</td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: 'right' }}>Validity</td>
                                  <td>
                                    <div className="nbn-progress"></div>
                                  </td>
                                  <td style={{ fontWeight: 600, textAlign: 'left' }}>Every {activationDetail.activated_plan_data.validity} Days</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-md-2 py-4">
                          <center>
                            <Link
                              onClick={this.menuClick}
                              to={{
                                pathname: "/home",
                                state: {selectedNumber:activationDetail.mobile_number,
                                  selectedActivation:activationDetail.activation_id
                                }
                              }}
                              className="kt-menu__link active"
                            >
                              <button type="button" className="btn btn-danger btn-data">
                                Manage <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                              </button>
                            </Link>

                          </center>
                        </div>
                      </div>
                    </div>
                  ))
                }
                <br />
            {/* Data Section 2 Ends Here */}


          </section>

          {/* Exclusive Member Deals */}
          <section className="nbn-my-account">
            <div className="nbn-my-service">
              <div className="row">
                <div className="col-md-7">
                  <h3>EXCLUSIVE MEMBER DEALS</h3>
                </div>
                <div className="col-md-5">
                  {/* You can add content here if needed */}
                </div>
              </div>
            </div>

            <div className="nbn-data-section">
              <div className="row">
                {/* Data Section 4 */}
                <div className="col-md-1 py-4">
                  <center><img src={nbnIcon} alt="nbn icon" width="60px" height="auto" /></center>
                </div>
                <div className="col-md-3 py-4">
                  <div className="nbn-data-content">
                    <strong>Can't see a Service?</strong>
                    <p>Maybe it has its own account</p>
                  </div>
                </div>
                <div className="col-md-6 py-4">
                  <div className="nbn-para-content">
                    <p style={{ textAlign: 'justify' }}>If you can't see a particular service here, it is most likely because it was created as a separate account when you ordered the service. You can merge accounts, so you can track all your services under the one My Account Login.</p>
                  </div>
                </div>
                <div className="col-md-2 py-4">
                  <center><a href=""><button type="button" className="btn btn-danger btn-data">Manage <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></button></a></center>
                </div>
              </div>
            </div>
          </section>
        </div>
           </>
        )
    }
}

export default MyServices;
